<template>
    <div class="executions-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
                <date-range-picker v-model="dateRange" opens="left" @update="requests(false)" class="mr-2" :ranges="dateRanges">
                    <template v-slot:input="picker" style="min-width: 350px;">
                        <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                        <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                    </template>
                </date-range-picker>
                <b-button v-b-modal.executionsColumnsModal variant="light">
                    Columns
                </b-button>
            </div>
        </div>
        <b-table id="executions-table" :items="filterItems()" :fields="getEnabledColumns()" responsive class="text-center" show-empty :per-page="50" :current-page="currentPage">
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <input type="text" v-model="filters[field.key]" placeholder="Search..." class="form-control" />
                </td>
            </template>
            <template slot="empty">
                <span id="defaultText">Loading Executions data...</span>
            </template>
        </b-table>
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="50" aria-controls="executions-table"></b-pagination>
            </div>
        </div>
        <b-modal id="executionsColumnsModal" title="Customise Columns" body-class="p-0" @show="setupColumnFiltersModal" @ok="finishColumnFilters" @hidden="resetColumnFilters">
            <draggable v-model="filteredColumns">
                <div v-for="column in filteredColumns" :key="column.key" class="column-filter" @click="onColumnFilterClick($event, column)">
                    <div class="d-flex align-items-center justify-content-between">
                        <b-form-checkbox v-model="column.enabled" :value="true" :unchecked-value="false">
                            {{column.label}}
                        </b-form-checkbox>
                        <span class="drag-handle">
                            <i class="mdi mdi-menu"></i>
                        </span>
                    </div>
                </div>
            </draggable>
            <template v-slot:modal-footer="{ ok, cancel }">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-row align-items-center justify-content-start">
                            <b-button variant="primary" @click="ok()">Confirm</b-button>
                            <b-button variant="secondary" @click="cancel()" class="ml-2">Cancel</b-button>
                        </div>
                        <div class="col-md-6 d-flex flex-row align-items-center justify-content-end">
                            <b-checkbox v-model="columnFiltersSave" :value="true" :unchecked-value="false">
                                Save as preset
                            </b-checkbox>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import draggable from 'vuedraggable';
import { exportCsv } from '@/lib/Exporting';
import { map, clone } from 'lodash';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';

export default {
    name: 'executions',
    data() {
        let that = this;

        let columns = [
            {
                key: 'account',
                label: 'Account',
                sortable: true,
                enabled: false
            },
            {
                key: 'longTime',
                label: 'LongTime',
                sortable: true,
                enabled: false
            },
            {
                key: 'strategyName',
                label: 'Strategy',
                sortable: true,
                enabled: false
            },
            {
                key: 'market',
                label: 'Market',
                sortable: true,
                enabled: false
            },
            {
                key: 'symbol',
                label: 'Symbol',
                sortable: true,
                enabled: false
            },
            {
                key: 'signalID',
                label: 'Signal ID',
                sortable: true,
                enabled: false
            },
            {
                key: 'requestID',
                label: 'Request ID',
                sortable: true,
                enabled: false
            },
            {
                key: 'tradeType',
                label: 'Trade Type',
                sortable: true,
                enabled: false
            },
            {
                key: 'requestedPrice',
                label: 'Requested Price',
                sortable: true,
                enabled: false
            },
            {
                key: 'requestedTimestamp',
                label: 'Requested Price',
                sortable: true,
                enabled: false
            },
            {
                key: 'requestedSize',
                label: 'Requested Size',
                sortable: true,
                enabled: false
            },
            {
                key: 'status',
                label: 'Status',
                sortable: true,
                enabled: false
            },
            {
                key: 'filledSize',
                label: 'Filled Size',
                sortable: true,
                enabled: false
            },
            {
                key: 'filledPrice',
                label: 'Filled Price',
                sortable: true,
                enabled: false
            },
            {
                key: 'filledTimestamp',
                label: 'Filled Timestamp',
                sortable: true,
                enabled: false
            },
            {
                key: 'tradingVenue',
                label: 'Trading Venue',
                sortable: true,
                enabled: false
            },
            {
                key: 'tradingVenueOrderID',
                label: 'Trading Venue Order ID',
                sortable: true,
                enabled: false
            },
            {
                key: 'returnBps',
                label: 'Return Bps',
                sortable: true,
                enabled: false
            },
            // {
            //     key: 'tradeReturnBps',
            //     label: 'Trade Return Bps',
            //     sortable: true,
            //     enabled: false
            // },
            {
                key: 'cashConversion',
                label: 'Cash Conversion',
                sortable: true,
                enabled: false
            },
            {
                key: 'rollover',
                label: 'Rollover',
                sortable: true,
                enabled: false
            },
            {
                key: 'bid',
                label: 'Bid',
                sortable: true,
                enabled: false
            },
            {
                key: 'ask',
                label: 'Ask',
                sortable: true,
                enabled: false
            },
            {
                key: 'confirmationTime',
                label: 'Confirmation Time',
                sortable: true,
                enabled: false
            },
            {
                key: 'expectedSpread',
                label: 'Expected Spread',
                sortable: true,
                enabled: false
            },
            {
                key: 'requestSource',
                label: 'Request Source',
                sortable: true,
                enabled: false
            },
            {
                key: 'positionID',
                label: 'Position ID',
                sortable: true,
                enabled: false
            },
            {
                key: 'tradeNumber',
                label: 'Trade Number',
                sortable: true,
                enabled: false
            }
        ];

        let sortedColumns = [];

        let loadedColumns = localStorage.getItem('executionsColumns');
        if(loadedColumns !== null) {
            loadedColumns = JSON.parse(loadedColumns);

            for(let key in loadedColumns) {
                for(let column of columns) {
                    if(column.key === key) {
                        column.enabled = loadedColumns[key];
                        sortedColumns.push(column);
                        break;
                    }
                }
            }

            for(let column of columns) {
                if(!loadedColumns.hasOwnProperty(column.key)) {
                    sortedColumns.push(column);
                }
            }
        } else {
            for(let column of columns) {
                column.enabled = true;
            }

            sortedColumns = columns;
        }

        let filters = {};
        for(let column of sortedColumns) {
            filters[column.key] = '';
        }

        let date = new Date();
        return {
            columns: sortedColumns,
            items: [],
            filters: filters,
            columnFiltersSave: true,
            filteredColumns: [],
            currentPage: 1,
            hasSetupListeners: false,
            dateRange: {
                startDate: new Date(`2020-${new Date(Date.now()).getMonth()+1}-01`),
                endDate: endOfMonth(date)
            },
            dateRanges: {
                'This Week': [startOfWeek(date), endOfWeek(date)],
                'This Month': [startOfMonth(date), endOfMonth(date)],
                'Last Month': [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))],
                'This Quarter': [startOfQuarter(date), endOfQuarter(date)],
                'Last Quarter': [startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))],
                'This Year': [startOfYear(date), endOfYear(date)],
                'Last Year': [startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))],
                'All Time': [new Date('2017-01-01'), endOfYear(date)]
            }
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        }
    },
    components: {
        draggable,
        DateRangePicker
    },
    mounted() {
        this.requests(true);
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('executions', this.onExecutions);
    },
    methods: {
        requests(initial = false) {
  
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('executions', this.onExecutions);
                this.hasSetupListeners = true;
            }
         
            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }
         
            this.items = [];
            if(initial && this.$store.state.executionCache){
                this.items = this.$store.state.executionCache;
                return;
            }
            
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'Executions',
                args: {
                    startDate:this.dateRange.startDate.getTime(),  //format(this.dateRange.startDate, 'yyyy-MM-dd'),
                    endDate:this.dateRange.endDate.getTime() //format(this.dateRange.endDate, 'yyyy-MM-dd')
                }
            });
        },
        onExecutions(event) {
            if(document.getElementById("defaultText")){document.getElementById("defaultText").innerHTML = "No results found"}
            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            if(event.request.accountName !== account) {
                return;
            }
            this.$store.state.executionCache = event.response;
            this.items = event.response;
        },
        getEnabledColumns() {
            let columns = [];

            for(let column of this.columns) {
                if(column.enabled) {
                    columns.push(column);
                }
            }

            return columns;
        },
        filterItems() {
            let filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()));
            });

            if(filtered.length > 0) {
                return filtered;
            } else {
                let emptyFilters = {};
                for(let key in this.filters) {
                    emptyFilters[key] = '';
                }

                return [];
            }
        },
        onColumnFilterClick(event, column) {
            let tagName = event.target.tagName.toLowerCase();

            if(tagName === 'input' || tagName === 'label') {
                return;
            }

            column.enabled = !column.enabled;
        },
        setupColumnFiltersModal() {
            this.filteredColumns = map(this.columns, clone);
        },
        finishColumnFilters() {
            this.columns = map(this.filteredColumns, clone);

            if(this.columnFiltersSave) {
                let columns = {};
                for(let column of this.columns) {
                    columns[column.key] = column.enabled;
                }

                localStorage.setItem('executionsColumns', JSON.stringify(columns));
            }
        },
        resetColumnFilters() {
            this.filteredColumns = [];
        },
        onExport() {
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            exportCsv(headers, map(this.items, clone), 'Executions');
        }
    }
}
</script>